<script setup lang="ts"></script>
<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from {
  opacity: 0.3;
  transform: translateX(-150%);
  filter: blur(1rem);
}

.page-leave-to {
  opacity: 0.3;
  transform: translateX(150%);
  filter: blur(1rem);
}
</style>