import { default as _4004IhgnqH1acMeta } from "/var/www/vhosts/wheetime.de/app/pages/error/400.vue?macro=true";
import { default as AccessDeniedeyXoCESX0GMeta } from "/var/www/vhosts/wheetime.de/app/pages/error/AccessDenied.vue?macro=true";
import { default as login_deniedPma3ra8ksqMeta } from "/var/www/vhosts/wheetime.de/app/pages/error/login_denied.vue?macro=true";
import { default as not_verified2lCFFqf1iNMeta } from "/var/www/vhosts/wheetime.de/app/pages/error/not_verified.vue?macro=true";
import { default as _91id_93oMOQhZEiN8Meta } from "/var/www/vhosts/wheetime.de/app/pages/group/[id].vue?macro=true";
import { default as createn7r6qGMuFTMeta } from "/var/www/vhosts/wheetime.de/app/pages/group/create.vue?macro=true";
import { default as edit_45_91id_93Gibqdkdqw0Meta } from "/var/www/vhosts/wheetime.de/app/pages/group/edit-[id].vue?macro=true";
import { default as index2Wp6XiSRwxMeta } from "/var/www/vhosts/wheetime.de/app/pages/group/index.vue?macro=true";
import { default as list_45myv0p5lMwV4nMeta } from "/var/www/vhosts/wheetime.de/app/pages/group/list-my.vue?macro=true";
import { default as imprintsxWTiWOkUOMeta } from "/var/www/vhosts/wheetime.de/app/pages/imprint.vue?macro=true";
import { default as indexDxtsAHdIfiMeta } from "/var/www/vhosts/wheetime.de/app/pages/index.vue?macro=true";
import { default as accounttRpraMY9tjMeta } from "/var/www/vhosts/wheetime.de/app/pages/user/account.vue?macro=true";
import { default as invites9VmPmO7E8rMeta } from "/var/www/vhosts/wheetime.de/app/pages/user/invites.vue?macro=true";
import { default as loginT3d6jUUjqFMeta } from "/var/www/vhosts/wheetime.de/app/pages/user/login.vue?macro=true";
import { default as logoutEBwYZJhuIgMeta } from "/var/www/vhosts/wheetime.de/app/pages/user/logout.vue?macro=true";
import { default as resend_verifyb79naYLjKVMeta } from "/var/www/vhosts/wheetime.de/app/pages/user/resend_verify.vue?macro=true";
import { default as signupOlfMuDjgFVMeta } from "/var/www/vhosts/wheetime.de/app/pages/user/signup.vue?macro=true";
import { default as verify_45_91token_93UqEvuXeaJVMeta } from "/var/www/vhosts/wheetime.de/app/pages/user/verify-[token].vue?macro=true";
export default [
  {
    name: "error-400",
    path: "/error/400",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/error/400.vue")
  },
  {
    name: "error-AccessDenied",
    path: "/error/AccessDenied",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/error/AccessDenied.vue")
  },
  {
    name: "error-login_denied",
    path: "/error/login_denied",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/error/login_denied.vue")
  },
  {
    name: "error-not_verified",
    path: "/error/not_verified",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/error/not_verified.vue")
  },
  {
    name: "group-id",
    path: "/group/:id()",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/group/[id].vue")
  },
  {
    name: "group-create",
    path: "/group/create",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/group/create.vue")
  },
  {
    name: "group-edit-id",
    path: "/group/edit-:id()",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/group/edit-[id].vue")
  },
  {
    name: "group",
    path: "/group",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/group/index.vue")
  },
  {
    name: "group-list-my",
    path: "/group/list-my",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/group/list-my.vue")
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/imprint.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexDxtsAHdIfiMeta || {},
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/index.vue")
  },
  {
    name: "user-account",
    path: "/user/account",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/user/account.vue")
  },
  {
    name: "user-invites",
    path: "/user/invites",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/user/invites.vue")
  },
  {
    name: "user-login",
    path: "/user/login",
    meta: loginT3d6jUUjqFMeta || {},
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/user/login.vue")
  },
  {
    name: "user-logout",
    path: "/user/logout",
    meta: logoutEBwYZJhuIgMeta || {},
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/user/logout.vue")
  },
  {
    name: "user-resend_verify",
    path: "/user/resend_verify",
    meta: resend_verifyb79naYLjKVMeta || {},
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/user/resend_verify.vue")
  },
  {
    name: "user-signup",
    path: "/user/signup",
    meta: signupOlfMuDjgFVMeta || {},
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/user/signup.vue")
  },
  {
    name: "user-verify-token",
    path: "/user/verify-:token()",
    component: () => import("/var/www/vhosts/wheetime.de/app/pages/user/verify-[token].vue")
  }
]